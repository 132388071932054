var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:{'persons__row-inactive': _vm.props.item.status == 'inactive', 'persons__row-negatived': _vm.props.item.negatived_count > 0}},[(_vm.config.config.fieldsSelected)?_c('td',{staticStyle:{"min-width":"30px !important"}},[_c('v-checkbox',{attrs:{"primary":"","hide-details":""},model:{value:(_vm.props.props.selected),callback:function ($$v) {_vm.$set(_vm.props.props, "selected", $$v)},expression:"props.props.selected"}})],1):_vm._e(),_c('wi-list-data-table-actions',{attrs:{"config":_vm.config,"props":_vm.props}}),_c('td',{staticClass:"justify-left"},[_c('strong',[_vm._v(_vm._s(_vm.props.item.tag || '---'))])]),_c('td',{staticClass:"justify-left"},[_c('v-chip',{staticClass:"ma-1 elevation-1",attrs:{"large":"","color":_vm.props.item.color}},[_c('span',{style:({ color: _vm.getTextColoe(_vm.props.item.color) })},[_vm._v(" "+_vm._s(_vm.props.item.name)+" ")])])],1),_c('td',{staticClass:"justify-center",staticStyle:{"cursor":"pointer"}},[(_vm.$WiCheckPermission('397f4f1e'))?_c('div',{on:{"click":function($event){return _vm.$router.push({
        name: 'talk-list',
        params: {
          page: 'talk-contact'
        },
        query: {
          tag_id: _vm.props.item.id
        }
      })}}},[_c('strong',{staticStyle:{"font-size":"20px","padding":"2px 10px","border-radius":"5px"},style:({ background: _vm.props.item.color, color: _vm.getTextColoe(_vm.props.item.color)})},[_vm._v(" "+_vm._s(_vm.props.item.persons_count || '0')+" ")]),_c('br'),_c('span',{staticStyle:{"color":"gray"}},[_vm._v(" Clique para listar ")])]):_c('div',[_vm._v(" - - - ")])]),_c('td',{staticClass:"justify-center",staticStyle:{"cursor":"pointer"}},[(_vm.$WiCheckPermission('397f4f1e'))?_c('div',{on:{"click":function($event){return _vm.$router.push({
        name: 'talk-list',
        params: {
          page: 'talk-contact'
        },
        query: {
          tag_id: _vm.props.item.id
        }
      })}}},[_c('strong',{staticStyle:{"font-size":"20px","padding":"2px 10px","border-radius":"5px"},style:({ background: _vm.props.item.color, color: _vm.getTextColoe(_vm.props.item.color)})},[_vm._v(" "+_vm._s(_vm.props.item.talk_contacts_count || '0')+" ")]),_c('br'),_c('span',{staticStyle:{"color":"gray"}},[_vm._v(" Clique para listar ")])]):_c('div',[_vm._v(" - - - ")])]),_c('td',{staticClass:"justify-left"},[_c('span',[_vm._v(_vm._s(_vm.props.item.description || '---'))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }