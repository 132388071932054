<template>
  <tr :class="{'persons__row-inactive': props.item.status == 'inactive', 'persons__row-negatived': props.item.negatived_count > 0}">
    <td v-if="config.config.fieldsSelected" style="min-width: 30px !important;">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <strong>{{ props.item.tag || '---' }}</strong>
    </td>
    <td class="justify-left">
      <v-chip large :color="props.item.color" class="ma-1 elevation-1">
        <span :style="{ color: getTextColoe(props.item.color) }">
          {{ props.item.name }}
        </span>
      </v-chip>
    </td>
    <td class="justify-center" style="cursor: pointer;">
      <div
        v-if="$WiCheckPermission('397f4f1e')"
        @click="$router.push({
          name: 'talk-list',
          params: {
            page: 'talk-contact'
          },
          query: {
            tag_id: props.item.id
          }
        })"
      >
        <strong style="font-size: 20px; padding: 2px 10px; border-radius: 5px;" :style="{ background: props.item.color, color: getTextColoe(props.item.color)}">
          {{ props.item.persons_count || '0' }}
        </strong><br>
        <span style="color: gray;">
          Clique para listar
        </span>
      </div>
      <div v-else>
        - - -
      </div>
    </td>
    <td class="justify-center" style="cursor: pointer;">
      <div
        v-if="$WiCheckPermission('397f4f1e')"
        @click="$router.push({
          name: 'talk-list',
          params: {
            page: 'talk-contact'
          },
          query: {
            tag_id: props.item.id
          }
        })"
      >
        <strong style="font-size: 20px; padding: 2px 10px; border-radius: 5px;" :style="{ background: props.item.color, color: getTextColoe(props.item.color)}">
          {{ props.item.talk_contacts_count || '0' }}
        </strong><br>
        <span style="color: gray;">
          Clique para listar
        </span>
      </div>
      <div v-else>
        - - -
      </div>
    </td>
    <td class="justify-left">
      <span>{{ props.item.description || '---' }}</span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Tags',
    methods: {
      getTextColoe: function (corHexadecimal) {
          // Converte a cor hexadecimal para RGB
          const r = parseInt(corHexadecimal.slice(1, 3), 16);
          const g = parseInt(corHexadecimal.slice(3, 5), 16);
          const b = parseInt(corHexadecimal.slice(5, 7), 16);

          // Calcula o valor de luminosidade usando a fórmula YIQ
          const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;

          // Define um valor de limiar para determinar a cor do texto
          const limiar = 128;

          // Verifica se a luminosidade é maior que o limiar
          if (luminosidade > limiar) {
              // Se a cor de fundo for clara, retorna cor escura
              return "#000000"; // Cor preta
          } else {
              // Se a cor de fundo for escura, retorna cor clara
              return "#FFFFFF"; // Cor branca
          }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
